import { Pipe, PipeTransform } from '@angular/core';
import { FollowupService } from '@followup/followup.service';

@Pipe({
    name: 'tags',
    standalone: true
})
export class TagsPipe implements PipeTransform {

  constructor(
    private _followup: FollowupService
  ){}

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      return `${this.genString(value)}`;
    } else {
      return null;
    }
  }

  genString(value: string): String{
    return `${this._followup.status.find(s => s.status == value).description}`;
  }

}
