import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FechaPipe } from './fecha.pipe';
import { EstadosPipe } from './estados.pipe';
import { RequestEstadosPipe } from './requestEstados.pipe';
import { SubEstadosPipe } from './subestados.pipe';
import { EdadPipe } from './edad.pipe';
import { ChatSenderPipe } from './chat-sender.pipe';
import { ChatStateBadgetPipe } from './chat-state-badget.pipe';
import { TreatmentNamePipe } from './treatment-name.pipe';
import { DataSolicitudPipe } from './data-solicitud.pipe';
import { BadgetsPipe } from './badgets.pipe';
import { TreatmentColorPipe } from './treatment-color.pipe';
import { Fecha2Pipe } from './fecha2.pipe';
import { Fecha3Pipe } from './fecha3.pipe';
import { BadgetsRecurrentPipe } from './badgetsRecurrent.pipe';
import { Fecha4Pipe } from './fecha4.pipe';
import { Fecha5Pipe } from './fecha5.pipe';
import { BadgetsDateTagPipe } from './badgetsDateTag.pipe';
import { EtapaPipe } from './etapa.pipe';
import { TagsPipe } from './tags.pipe';
import { TagPipe } from './tag.pipe';
import { GridTagPipe } from './grid.pipe';
import { FechaHoraPipe } from './fechaHora.pipe';
import { GridTagColorPipe } from './gridColorTag.pipe';



@NgModule({
    imports: [
        CommonModule,
        FechaPipe,
        RequestEstadosPipe,
        EstadosPipe,
        EtapaPipe,
        SubEstadosPipe,
        EdadPipe,
        GridTagPipe,
        GridTagColorPipe,
        ChatSenderPipe,
        ChatStateBadgetPipe,
        TreatmentNamePipe,
        DataSolicitudPipe,
        BadgetsPipe,
        TagsPipe,
        TagPipe,
        BadgetsRecurrentPipe,
        BadgetsDateTagPipe,
        TreatmentColorPipe,
        Fecha2Pipe,
        Fecha3Pipe,
        Fecha4Pipe,
        Fecha5Pipe,
        FechaHoraPipe
    ],
    exports: [
        FechaPipe,
        Fecha2Pipe,
        Fecha3Pipe,
        Fecha4Pipe,
        EstadosPipe,
        RequestEstadosPipe,
        SubEstadosPipe,
        EdadPipe,
        GridTagPipe,
        GridTagColorPipe,
        EtapaPipe,
        TagsPipe,
        TagPipe,
        ChatSenderPipe,
        ChatStateBadgetPipe,
        TreatmentNamePipe,
        DataSolicitudPipe,
        BadgetsPipe,
        BadgetsRecurrentPipe,
        BadgetsDateTagPipe,
        FechaHoraPipe
    ],
    providers: [
        DatePipe,
        FechaPipe,
        Fecha2Pipe,
        Fecha3Pipe,
        Fecha4Pipe,
        Fecha5Pipe,
        RequestEstadosPipe,
        EstadosPipe,
        GridTagPipe,
        GridTagColorPipe,
        SubEstadosPipe,
        EdadPipe,
        EtapaPipe,
        ChatSenderPipe,
        ChatStateBadgetPipe,
        TreatmentNamePipe,
        DataSolicitudPipe,
        BadgetsPipe,
        TagsPipe,
        TagPipe,
        BadgetsRecurrentPipe,
        TreatmentColorPipe,
        BadgetsDateTagPipe,
        FechaHoraPipe
    ]
})
export class PipesModule { }
